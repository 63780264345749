document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.mobileNav__list .menu-item-has-children')) {
		let subMenus = document.querySelectorAll(
			'.mobileNav__list .menu-item-has-children'
		);
		subMenus.forEach((subMenu) => {
			let link = subMenu.querySelector('a');
			let span = subMenu.querySelector('span');
			let ul = subMenu.querySelector('ul');
			link.addEventListener('click', (e) => {
				if (subMenu.querySelector('span')) {
					e.preventDefault();
					ul.classList.toggle('sub-menu--open');
					span.classList.toggle('open');
				}
			});
		});
	}
	const hamburger = document.querySelector('.header .hamburger');
	const mobileNav = document.querySelector('.mobileNav');
	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('hamburger--opened');
		hamburger.setAttribute(
			'aria-expanded',
			hamburger.classList.contains('hamburger--opened')
		);
		mobileNav.classList.toggle('open');
	});
	const header = document.querySelector('.header');
	window.addEventListener('scroll', () => {
		document.body.scrollTop > 40 || document.documentElement.scrollTop > 40
			? header.classList.add('header--scrolled')
			: header.classList.remove('header--scrolled');
	});

	const btn = document.querySelector('.scrollTop');

	btn.addEventListener('click', () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

	window.onscroll = function () {
		scrollFunction();
	};

	function scrollFunction() {
		if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
			btn.style.display = 'flex';
		} else {
			btn.style.display = 'none';
		}
	}

	if (document.querySelector('.intro')) {
		const intro = new Swiper('.intro .swiper', {
			loop: 1,
			slideperView: 1,
			effect: 'fade',
			speed: 1400,
			autoplay: {
				delay: 5000,
			},
		});
	}

	if (document.querySelector('.opinions')) {
		const opinions = new Swiper('.opinions .swiper', {
			loop: 1,
			slidesPerView: 1,
			spaceBetween: 30,
			speed: 2000,
			autoplay: {
				delay: 3000,
			},
			breakpoints: {
				868: {
					slidesPerView: 2,
					spaceBetween: 50,
				},
				1480: {
					slidesPerView: 2.4,
					spaceBetween: 50,
				},
			},
		});
	}

	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

	// var questionSets = document.querySelectorAll('.faqBlock__questions');
	// const questionSets2 = document.querySelectorAll('.faq__questions');

	// questionSets = [...questionSets, ...questionSets2];
	// questionSets.forEach((questionSet) => {
	// 	var questions = questionSet.querySelectorAll('.faqQuestion');

	// 	questions.forEach((question) => {
	// 		question.addEventListener('click', () => {

	// 			questions.forEach(q=>{
	// 				var index = parseInt(q.getAttribute('data-index'))
	// 				var newIndex = 0;
	// 				if(q.hasAttribute('open')){
	// 					if(index%2){
	// 						newIndex = index+1;
	// 					}else{
	// 						newIndex = index-1;
	// 					}
	// 					console.log(newIndex)
	// 					var quest = questionSet.querySelector(`.faqQuestion[data-index="${newIndex}"]`)
	// 					console.log(quest)
	// 					setTimeout(()=>{
	// 						if(!quest.hasAttribute('open')){
	// 							quest.style.height = "fit-content"
	// 						}else{
	// 							quest.style.height = ""
	// 						}
	// 						console.log('now')
	// 					},450)
	// 				}
	// 			})

	// 		});
	// 	});
	// });

	var faqs = document.querySelectorAll('.faq__questions');
	var blockFaqs = document.querySelectorAll('.faqBlock__questions');
	faqs = [...faqs, ...blockFaqs];
	console.log(faqs)
	faqs.forEach((faq) => {
		var questions = faq.querySelectorAll('.faqQuestion');
		questions.forEach((question) => {
			var index = parseInt(question.getAttribute('data-index'));
			if (index % 2 == 0) {
				var qs = faq.querySelector(`.faqQuestion[data-index="${index - 1}"]`);
				if (qs) {
					console.log(qs, question);
					console.log(qs.offsetHeight, question.offsetHeight);
					if (qs.offsetHeight != question.offsetHeight) {
						if (qs.offsetHeight < question.offsetHeight) {
							var summary = qs.querySelector('summary');
							summary.style.minHeight = question.offsetHeight + 'px';
						} else {
							var summary = question.querySelector('summary')
							summary.style.minHeight = qs.offsetHeight + 'px'
						}
					}
				}
			}
		});
	});
});
