if (document.querySelector('.modal')) {
	const buttons = document.querySelectorAll('.searchModalButton');
	var modal = document.getElementById('modal');
	var modalOverlay = document.querySelector('#modal-overlay');
	var closeButton = document.querySelector('#close-button');
	var content = document.querySelector('.modal-form');
	closeButton.addEventListener('click', () => {
		modal.classList.add('closed');
		modalOverlay.classList.add('closed');
	});
	buttons.forEach((button) => {
		button.addEventListener('click', async () => {
				modal.classList.remove('closed');
				modalOverlay.classList.remove('closed');
		});
	});
}

