document.addEventListener('DOMContentLoaded', () => {
	const textAreas = document.querySelectorAll('textarea');
    textAreas.forEach(textArea =>{
        textArea.setAttribute('style',`height: ${textArea.scrollHeight} px; overflow-y:hidden;`)
        textArea.addEventListener('input',()=>{
            textArea.style.height = "auto";
            textArea.style.height = textArea.scrollHeight + 'px'
        })
    })
});
