document.addEventListener('DOMContentLoaded', () => {
	const counters = document.querySelectorAll('.counter');

	counters.forEach((counter) => {
		const updateCounter = () => {
			var before = counter.getAttribute('data-counter-before');
			const speed = parseInt(counter.getAttribute('data-counter-speed'));
			const after = counter.getAttribute('data-counter-after');
			var target = parseFloat(counter.getAttribute('data-counter')) * 1000;
			const precision = parseInt(counter.getAttribute('data-counter-precision'));
			var currentValue = parseInt(counter.getAttribute('data-counter-current'));
			target = precision ? target : target;

			var increment = Math.trunc(target / speed);

			if (before == 'arrow') {
				before = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
				<path d="M4.41183 8.43433C4.41183 8.75916 4.67517 9.02249 5 9.02249C5.32483 9.02249 5.58817 8.75916 5.58817 8.43433L4.41183 8.43433ZM5.4159 0.372278C5.1862 0.142585 4.8138 0.142585 4.5841 0.372278L0.841041 4.11534C0.611347 4.34503 0.611348 4.71744 0.841041 4.94713C1.07073 5.17683 1.44314 5.17683 1.67283 4.94713L5 1.61997L8.32717 4.94713C8.55686 5.17683 8.92927 5.17683 9.15896 4.94713C9.38865 4.71744 9.38865 4.34503 9.15896 4.11534L5.4159 0.372278ZM5.58817 8.43433L5.58817 0.788174L4.41183 0.788174L4.41183 8.43433L5.58817 8.43433Z" fill="white"/>
				</svg>`;
			}

			if (currentValue < target) {
				currentValue += increment;
				counter.setAttribute('data-counter-current', currentValue);
				currentValue /= 1000;
				if (precision) {
					currentValue = currentValue.toFixed(1);
				}else{
					currentValue = currentValue.toFixed(0)
				}
				counter.innerHTML = before + currentValue + after;
				setTimeout(updateCounter, 1);
			} else {
				target /= 1000;
				if (precision) {
					target = target.toFixed(1);
				}
				counter.innerHTML = before + target + after;
			}
		};

		updateCounter();
	});
});
